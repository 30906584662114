.content-section {
    h2 {
        text-align: center;
        width:100%;
        font-size: 4rem;
        margin: 8rem 0 8rem 0;
        text-transform: uppercase;
    }

    h3 {
        text-align: center;
        width:100%;
        font-size: 2rem;
        margin: 8rem 0 3rem 0;
    }

    &__main {
        width: 100%;
    }
}