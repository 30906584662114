.secondary-footer {
    background-color: #111;
    color:#777;
    font-size:1rem;
    padding: 1rem 1rem 0rem 1rem;
    text-align: left;
    width: 100%;
    pre {
        margin-top:1rem;
        display:block;
        font-size: 0.9rem;;
        width:fit-content;
        margin:auto;
    }
    a {
        cursor: pointer;
        margin-bottom: 0rem;
        display: block;
        text-align: right;
    }
}