.what-section {
    background-color:#3870F5;
    //position: relative;
    right: 0;
   // width: 100%;
    min-height: 100vh;
    padding: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fff;
    z-index:2;
}