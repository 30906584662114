@import 'sass/abstract/mixins';

.calc-section {
    //background-color:rgb(109, 179, 109);
    background-color: #4F8C7B;
   // position: relative;
    right: 0;
    //width: 100%;
   // min-height: 100vh;
    //padding: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fefefe;
    @include respond(tab-port) {
        .box {
            width: 100% !important;
        }
    }
    .box {
        width: 80rem;
        margin:auto;
        border-radius: 10px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        padding: 3rem;
        text-align: left;
        background-color: #eee;
        margin-bottom: 6rem;
        color: #333;
    }
    .co2result{
        text-align:right;
        margin-top: 3rem;;
        &--result {
            font-weight: 800;
        }
    }
    .calc-row {
        display:flex;
    }
    .calc-input {
        border: 1px solid #999;
        width: 4.5rem;
        text-align: center;
        height: 3rem;
        font-size: 1.8rem;
        padding: 1rem 1rem;
        border-radius: 5px;
    }
    .rc-slider {
        margin: auto;
        margin-right: 2rem;
    }

    .calc-intro-text {
        text-align: center;
        font-weight:600;
        font-size:1.7rem;
        max-width: 80rem;
        display:block;
        margin: -4rem auto 6rem auto;
    }
}