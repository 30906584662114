.toc {
    background-color: #333;
    color:#eee;
    padding: 5rem 3rem;
    p {
        margin: 2rem 5rem;
    }
    .toc-back {
        background-color: #068d67;
        padding: 3rem;
        margin: -2rem -5rem;
        margin-bottom: 5rem;
        margin-top:5rem;

        a {
            font-size: 2rem;
            margin-left:5rem;
        }
    }
}
