.buy-section {
    background-color: #df9f3eed;
   // position: relative;
   right: 0;
   //width: 100%;
  // min-height: 100vh;
   //padding: 10rem;
   display: flex;
   align-items: center;
   color:#fefefe;

    .account-creation-box {
        max-width: 80rem;
        margin:auto;
        border-radius: 10px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        padding: 3rem;
        text-align: left;
        background-color: #eee;
        .label {
            color:#333;
            font-size: 1.8rem;
            margin-left:1rem;
            margin-bottom:1rem;
        }
        input, select{
            padding: 1rem 3rem;
            border-radius: 20px;
            font-size: 1.8rem;;
            border: 0;
            width: 100%;
            border: 1px solid #ccc;
            margin-bottom: 3rem;
        }
        .form-element--btn {
            text-align: center;;
        }
        button {
            margin:auto;
            font-size: 1.8rem;
            padding: 1rem 5rem;
            border-radius: 10px;
            border: 1px solid #ccc;
            background-color: #8D35E6;
            color:white;
            cursor: pointer;
        }
        button:disabled{
            background-color: #c9bfd3;
            cursor:inherit
        }
        .success {
            width: 100%;
            text-align: center;
            margin-bottom: 3rem;
            color:#4F8C7B;
            font-size: 1.9rem;;
            font-weight: 600;
        }
    }   
}