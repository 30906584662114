@import 'sass/abstract/mixins';

.how-section {
    //background-color:rgb(109, 179, 109);
    background-color: #4F8C7B;
   // position: relative;
    right: 0;
    //width: 100%;
    min-height: 100vh;
    //padding: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fefefe;

    @include respond(tab-port) {
        .chart-container  {
            .flow-chart {
                padding: 3rem 0 3rem 0;
                width:100%;
            }
        }
       }
    .chart-container {
        //margin: 0 -100px 0 -100px;
       // margin-left: -10rem;
        background-color: #efefef;
        width:100%;
        text-align: center;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);

        .flow-chart {
            padding: 3rem 0 3rem 0;
            max-height: 50rem;
        }
    }
    .how-intro-text {
        text-align: center;
        font-weight:600;
        font-size:1.7rem;
        max-width: 80rem;
        display:block;
        margin: 0 auto 4rem auto;
    }
    .closing-text {
        background-color: #efefef;
        color:#333;
        padding: 5rem 1rem;
        border: 0px solid #333;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);

        border-radius: 0px;
        text-align: center;
        font-weight:400;
        font-size:1.7rem;
        //max-width: 80rem;
        display:block;
        margin: 4rem auto 4rem auto;
        .code {
            display:block;
            margin-top: 3rem;
            font-family: 'Ubuntu Mono', monospace;
            font-weight:800;
            color: rgb(186, 0, 211);

            &--cmd{
                color:rgb(0, 32, 214);
            }

            &--arg{
                color:rgb(121, 135, 216);
            }

            &--prot{
                color:rgb(175, 175, 175);
            }

            &--domain{
                color:rgb(3, 61, 7);
            }

            &--path{
                color:rgb(0, 151, 33);
            }
        }
    }

    .list-container{
        max-width: 100rem;
        margin: auto;
        text-align: center;

        li {
            background-color: white;
            color: #333;
            margin: 1rem;
            border-radius: 10px;
            display: inline-flex;
            align-items: center;
            padding: 1rem 2rem;
            white-space: nowrap;
            img {
                margin-right: 1.2rem;
            }
        }
    }
}