@import 'sass/abstract/variables';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;

}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  box-sizing: border-box;
}
#root {
  height: 100vh;
}

.btn {
  &,
  &:link,
  &:visited {
      text-decoration: none;
      padding: .8rem 6rem;
      display: inline-block;
      border-radius: 10rem;
      transition: all .2s;
      position: relative;
      font-size: 2rem;
      font-family: inherit;
      //Change for the <button> element
      border: none;
      outline: none;
      cursor: pointer;
      background-color:$color-grey-dark;
      color: $color-primar-text-1;
  }
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba(#000,.2);
  }
  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba(red,.2);
  }
}

.btn--primary {
  background-color:$color-primary-1;
  color: white;
}
ul {
  list-style: none;
}
a, a:visited, a:link {
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}
a:hover {
  font-weight: 600;
}

.pxl-tooltip {
  font-size: 12px !important;
}

.sort-header {
  position: relative;
  &--asc {
    &:after {
      content: '';
      right: -15px;
      bottom: 8px;
      width: 7px;
      height: 7px;
      border: solid $color-primar-text-1;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
    }
  }
  &--desc {
    &:after {
      content: '';
      right: -15px;
      bottom: 5px;
      width: 7px;
      height: 7px;
      border: solid $color-primar-text-1;
      border-width: 0 2px 2px 0;
      transform: rotate(225deg);
      position: absolute;
    }
  }
}