
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: $color-primar-text-1;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
}
