@import 'sass/abstract/mixins';

.pricing-section {
    background-color:#5d6cac;
   // position: relative;
   right: 0;
   //width: 100%;
   //min-height: 100vh;
   //padding: 10rem;
   display: flex;
   align-items: center;
   color:#fefefe;

   @include respond(tab-port) {
    .pricing {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
}
   .pricing {
       display: flex;
       width:100%;
       flex-direction: row;
       max-width: 160rem;
       margin: auto;
       justify-content: space-evenly;
   }
   .pricing-box {
       flex:1;
       width:100%;
       background-color: white;
       color:#333;
       margin: 2rem;
       display: flex;
       max-width: 40rem;
       flex-direction: column;
       justify-content: flex-start;
       border-radius: 10px;
       box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
       padding-bottom:4rem;
       h3{
        margin-bottom: 0;
        font-size: 4rem;
       }
       h5{
        font-weight: 500;
        font-size: 1.2rem;
        text-align: center;
        color:#777;
        margin-bottom: 3rem;
       }
       h4{
           text-align: center;
           background-color: #eee;
           padding: 3rem;
           margin-bottom:3rem;
       }
       .text {
           background-color: #fff;
           color:#777;
           font-size: 1.4rem;
           border-radius: 10px;;
       }
       p {
        padding: 2rem;
        text-align: center;
    }
        p:not(:last-child) {
     
            border-bottom: 1px solid #ccc;
        }
       
   }
}
