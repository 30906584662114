@import 'sass/abstract/mixins';

.why-section {
   // background-color:#FC6C68;
 
   background:  linear-gradient(
    rgba(34, 34, 34, 0.4), 
    rgba(34, 34, 34, 0.01)
  ), url('../assets/desert.jpg');
   background-size:cover;
 
 //background-color: rgb(150,120,110);
   // position: relative;
   right: 0;
   //width: 100%;
   min-height: 100vh;
   //padding: 10rem;
   display: flex;
   align-items: center;
   color:#fefefe;

   .why-intro-text {
    text-align: center;
    font-weight:600;
    font-size:1.7rem;
    max-width: 80rem;
    display:block;
    margin: 0 auto 4rem auto;

   
}
.why-section::before{
    content:"";
    position: absolute;
    top:0;
    background-color: #c54b65;
     min-height: 100vh;

}

@include respond(tab-port) {
    .chart-container  {
        .flow-chart {
            padding: 3rem 0 3rem 0;
            width:100%;
        }
    }
   }
.chart-container {
    //margin: 0 -100px 0 -100px;
   // margin-left: -10rem;
    background-color: #efefef;
    
    margin:auto;
    text-align: center;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    margin-bottom:4rem;

    .flow-chart {
        padding: 3rem 0 3rem 0;
        max-height: 40rem;
    }
}
}