@import 'sass/abstract/mixins';

//@import url('https://.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
html {
  //overflow:hidden;
      /* CSS custom property for the polyfill */
      --scroll-behavior: smooth;

      /* Normal CSS property for browsers with native support */
      scroll-behavior: smooth;
}
body{
 // overflow: hidden;
}

sup {
vertical-align: super;
}
.float-toggle {
  position: fixed;
  width: 60px;
  height: 60px;
  background: url('../assets/menu.png');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
  right:10rem;
  top:4rem;
  transition: 0.3s;
  z-index:4001;
}
.float-toggle--bg {
  position: fixed;
  width: 60px;
  height: 60px;
  background: #444;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
  right:10rem;
  top:4rem;
  transition: 0.3s;
  z-index:2001;
  opacity: .5;
}

@include respond(tab-port) {
header {
  padding: 4rem 1rem !important;
}
}
header {
  color:white;
  position: absolute;
  z-index:4000;
  top:0;
  left:0;
  width:100%;
  padding: 4rem 10rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  
  .toggle {
    position: relative;
    width: 60px;
    height: 60px;
    background: url('../assets/menu.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center;
    cursor: pointer;
    display:none;
  }
}
.toplevel-container {
 // transition: .5s;
 overflow:hidden;
}
.container {
  position: absolute;
  transition: 0.3s;
  width: 100%;
  right:0;
  z-index:3000;
  .content-section {
    transition: .3s;
    position:relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    
  }
 // overflow-y: scroll;
  height: 100vh;
  &.active {
    .showcase {
      right: 300px;
    }
    .content-section {
      margin-right: 300px;
      transition: .3s;
     

    }
    .float-toggle {
      background: url('../assets/close.png');
      background-repeat: no-repeat;
      background-size: 25px;
      background-position: center;
      z-index:4001;
      right:400px;
    }
    .float-toggle--bg {
      background: #444;
      opacity: .5;
      background-repeat: no-repeat;
      background-size: 25px;
      background-position: center;
      z-index:4000;
      right:400px;
    }
  }

}
section {
  scroll-snap-align: start;
  scroll-margin: 100px;
  z-index: 2000;
  //scroll-snap-stop: always;
}
@include respond(tab-port) {
.showcase {
  padding: 1rem !important;
}
}
.showcase {
  position: relative;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:#111;
  color:#fff;
  z-index:3000;
  transition: .3s;


  

  video {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: cover;
    opacity: .8;
  }
  @include respond(tab-port) {
    text {
      width: 100% !important;
    }
    h2 {
      font-size:3rem !important;
    }
    h3{
      font-size:2rem !important;
    }
    p {
      font-size:1.4rem !important;
    }
  }
  .text {
    position: relative;
    z-index: 8000;
    h2 {
      font-size: 7rem;
      font-weight: 800;
      line-height: 1em;
      text-transform: uppercase;
    }
    h3 { 
      font-size: 6rem;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase;
    }
    p {
      font-size: 1.8rem;
      margin: 2rem 0;
      font-weight: 400;
      max-width: 70rem;
    }
    a {
      display: inline-block;
      background: #fff;
      font-size: 1.8rem;
      padding: 1rem 3rem;
      color: #111;
      margin-top: 1rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: .2s;
      &:hover {
        letter-spacing: 6px;
      }
    }
  }
  .overlay {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgb(83, 110, 78);
    mix-blend-mode: overlay;
  }
}

.menu.active {
  z-index:4000;
  right:0;
}
@include respond(tab-port) {
  .float-toggle {
    right:2rem !important;
    top:4rem;

  }
  .float-toggle--bg {

    right:2rem !important;
    top:4rem;

  }
  .container.active {
    .showcase {
        right: 100px !important;
    }
    .content-section {
      margin-right: 100px !important; 
    }
    .float-toggle {
      right:120px !important;
    }
    .float-toggle--bg {
      right:120px !important;
    }
  }
 
  .menu.active {
    right:0 !important;
  }
  .menu {
    right:-100px !important;
    width:100px !important;
    li {
      margin-bottom: 2rem;
    }
    a.menu__link {
      font-size: 1.5rem !important;
    }
  }
}
.menu {
  transition: .3s;
    position:fixed;
    top:0;
    right:-300px;
    width:300px;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background:white;
    z-index:4000;
    a.menu__link {
      font-size: 2.8rem;
      color: #111;
      font-weight: 700;
      &:hover {
        font-weight: 700;
        color: rgb(25, 143, 4);
    }
  }
  &__buy{
    margin-top:3rem;
    background: orangered;
    color: white;
    //outline: 1px solid rgb(187, 255, 0);;

    a.menu__link{
      color: white;
    }
    a.menu__link:hover{
      font-weight: 800;
      color:white;
    }
    &:hover {
      //outline: 5px solid rgb(56, 240, 0);
    }
  }
}

@include respond(tab-port) {
  footer {
    flex-direction: column !important;
    padding: 2rem 2rem !important;
    a {
      margin-left:0 !important;
      margin-right:1rem !important;
    }
  }
 }
footer {
  z-index:8000;
  background-color: #333;
  color: #eee;
  padding: 3rem 15rem;
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  .links {
    white-space: nowrap;
    a {
      margin-left:2rem;
    }
    a:before {
      content:'# ';
    }
    margin-left:auto;
  }
  font-size:1.3rem;
}