// COLORS
$color-primary-1: #e9425e;
$color-secondary-1: #00a1ed;
$color-primar-text-1: #1a1818;
$color-primar-text-2: #565656;
$color-secondary-text-1: #8d8d8d;

$color-grey-light-1: #fbfbfb;
$color-grey-light-2: #f3f3f3;
$color-grey-dark-1: #707070;
$color-background-light-1: #fff;

$color-error-1: #A62636;

// FONT
$default-font-size: 1.6rem;





$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;


$color-pxl-red-1:#D14D6A;
$color-pxl-red-2: #EE5B71;
$color-pxl-red-3:#B53B46;


$color-pxl-blue-1:#01AEEE;
$color-pxl-blue-2: #0582D7;
$color-pxl-blue-3:#EFEFEF;
$color-pxl-orange-1: #F9933D;



// GRID
$grid-width: 100%;
$gutter-vertical: 4rem;
$gutter-vertical-small: 2rem;
$gutter-horizontal: 4rem;