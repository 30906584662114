@function row($num) {
  @return #{$num * (-40) - 2}px;
}
@function col($num) {
  @return #{$num * (-40) - 2}px;
}
.icon {
  display: inline-block;
  //background: url("../resources/PXL_Icons.svg");
  width: 36px;
  height: 36px;
  overflow: hidden;
  vertical-align: middle;
}
.icon--small {
  zoom: .6;
}
.icon-check {
  @extend .icon;
  background-position: col(4) row(0);
}

.icon-close {
  @extend .icon;
  background-position: col(3) row(0);
}

.icon-settings {
  @extend .icon;
  background-position: col(1) row(0);
}

.icon-trash {
  @extend .icon;
  background-position: col(2) row(0);
}

.icon-edit {
  @extend .icon;
  background-position: col(1) row(0);
}

.icon-adduser {
  @extend .icon;
  background-position: col(0) row(0);
}

.icon-report {
  @extend .icon;
  background-position: col(2) row(1);
}

.icon-house {
  @extend .icon;
  background-position: col(3) row(1);
}

.icon-arrow-right {
  @extend .icon;
  background-position: col(4) row(1);
}

.icon-arrow-right-red {
  @extend .icon;
  background-position: col(3) row(2);
}
.icon-plus {
  @extend .icon;
  background-position: col(0) row(2);
}

.icon-login-as {
  @extend .icon;
  background-position: col(1) row(2);
}

.icon-logout-as {
  @extend .icon;
  background-position: col(2) row(2);
}

.icon-more {
  @extend .icon;
  background-position: col(0) row(3);
  height: 36px;
}

.icon-status-open {
  @extend .icon;
  width: 30px;
  background-position: col(1) row(3);
  vertical-align: middle;
}

.icon-status-complete {
  @extend .icon;
  width: 30px;
  background-position: col(2) row(3);
  vertical-align: middle;
}

.icon-status-error {
  @extend .icon;
  width: 30px;
  background-position: col(3) row(3);
  vertical-align: middle;
}

.icon-info {
  @extend .icon;
  background-position: col(4) row(3);
}

.icon--dummy {
  @extend .icon;
  background-position: -1px -41px;
}

.icon-ascending {
  @extend .icon;
  background-position: col(1) row(4);
}

.icon-descending {
  @extend .icon;
  background-position: col(0) row(4);
}
